import React from "react";
import "./../css/home.css";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
function Home() {
  return (
    <>
      <div
        className="d-flex flex-column align-items-center justify-content-center"
        style={{ height: "80vh" }}
      >
        <h1 className="name">Vonetta Stevenson</h1>
        <h2 className="title">Web Designer</h2>
        <div className="p-5">
          <Button variant="outline-dark" size="lg">
            <Link to="/projects" class="view-projects">
              View Projects
            </Link>
          </Button>
        </div>
      </div>
    </>
  );
}

export default Home;
