import React from "react";
import ProjectItem from "./global/ProjectItem"
import data from "./../data/website.json"
function Flyers() {
  return (
    <div>
      <h1 className="text-center mt-5">Flyers</h1>
      <ProjectItem data={data.images.flyers}/>
    </div>
  );
}

export default Flyers;
