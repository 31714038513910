import React from "react";

import "../../css/projectItem.css";

import { Launch } from "@material-ui/icons";

function ProjectItem(props) {
  return (
    <>
      <div className="wrapper">
        {props.data.map((item, index) => (
          <div className="overlay">
            {item.link ? (
              <div className="text-right mr-3 view-site">
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  <Launch /> View Site
                </a>
              </div>
            ) : (
              ""
            )}
            <h2 className="text-left">{item.title}</h2>
            <div className="card mt-2">
              <img src={item.image} alt={item.title} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default ProjectItem;
