import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";

function Contact() {
  const [fullName, setfullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const submission = {
    fullName,
    email,
    message,
    dateOfSubmission: new Date(),
  };

  async function submitForm(e) {
    e.preventDefault();
    const response = await fetch(
      "https://port2020folio.herokuapp.com/contact",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Methods": "POST",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Origin, Content-Type, Methods",
        },
        body: JSON.stringify(submission),
      }
    );
    if (response.ok) {
      setfullName("");
      setEmail("");
      setMessage("");
    }
  }

  return (
    <div className="container mt-5">
      <h1>Contact Me</h1>
      <Form className="mt-5" onSubmit={submitForm}>
        <Form.Group controlId="formBasicName">
          <Form.Label>Full Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter full name"
            onChange={(e) => setfullName(e.target.value)}
            value={fullName}
          />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlTextarea1">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
}

export default Contact;
