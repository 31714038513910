import React from "react";
import ProjectItem from "./global/ProjectItem";
import data from "./../data/website.json";
function Websites() {
  return (
    <div>
      <h1 className="text-center mt-5"> Websites</h1>
      <ProjectItem data={data.images.websites} />
    </div>
  );
}

export default Websites;
