import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/global/Header";
import Home from "./components/Home";
import ProjectsMenu from "./components/ProjectsMenu";
import Websites from "./components/Websites";
import Flyers from "./components/Flyers";
import Contact from "./components/Contact";
function App(props) {
  return (
    <div className="App">
      {props.location.pathname !== "/" ? <Header /> : ""}
      <Switch>
        <Route path="/projects/websites" component={Websites} />
        <Route path="/projects/flyers" component={Flyers} />
        <Route path="/contact" component={Contact} />
        <Route exact path="/projects" component={ProjectsMenu} />
        <Route exact path="/" component={Home} />
      </Switch>
    </div>
  );
}

export default withRouter(App);
