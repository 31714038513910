import React from "react";
import "./../css/projectMenu.css";
import { Link } from "react-router-dom";
import codeBackground from "./../imgs/code.jpeg";
import colorPencils from "./../imgs/colorPencils.jpeg";

function ProjectsMenu() {
  return (
    <>
      <div
        className="d-flex align-items-center justify-content-center wrap"
        style={{ height: "80vh" }}
      >
        <div className="tile">
          <Link to="/projects/websites">
            <img src={codeBackground} alt="Code Background" />
            <div className="text">
              <h1>Websites</h1>
              <h2 className="animate-text">Website designs.</h2>
              <p className="animate-text">
                View a variety of websites that are built with various
                technologies{" "}
              </p>
            </div>
          </Link>
        </div>

        <div className="tile">
          <Link to="/projects/flyers">
            <img src={colorPencils} alt="Color Pencil Bakground" />
            <div className="text">
              <h1>Flyers</h1>
              <h2 className="animate-text">Flyer Designs</h2>
              <p className="animate-text">
                View a variety of flyers for different occassions{" "}
              </p>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

export default ProjectsMenu;
